/* You can add global styles to this file, and also import other style files */
$enable-shadows:                              true;
$enable-responsive-font-sizes:                true;

@import "bootswatch/dist/sandstone/variables";
@import "bootstrap/scss/bootstrap";
@import "bootswatch/dist/sandstone/bootswatch";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  padding-top: 125px;
}

.document-list-viewport {
  height: 83vh;
}

.mt-virtual-scroll-table {
  height: 83vh;
}

.dropdown-large{min-width:500px;}

.mt-large-select {
  max-width: 15em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mt-large-ng-select {
  width: auto !important;
}

.ng-select.custom .ng-dropdown-panel {
  width: auto !important;
}

.mt-large-text {
  display: block;
  max-width: 15em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mt-column-action {
  text-decoration: underline;
}

.mt-column-action:hover {
  cursor: pointer;
}

.mt-account-list-item {
  height: 60px;
}

.mt-fixed-top {
  margin-top: 54px;
  z-index:3;
  //margin-left: 225px;
}

.mt-checkbox-col, .mt-actions-col {
  width: 1%;
  white-space: nowrap;
}

.mt-content-col:hover {
  cursor: pointer;
}

.mt-left-panel-wrapper {
  width: 225px;
  bottom: 0;
  top: 0;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  padding-top: 125px;
}

.mt-content-wrapper {
  display: flex;
}

.mask {
  position: absolute;
  height: 100%;
  width: 100%;
  /*background-color: #FFF;*/
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  /*opacity: 0.4;*/
}

html, body { height: 100%; }


.mt-content {
  margin-left: 225px;
}
